import { Injectable, SecurityContext, Type } from '@angular/core';
import { FormGroup } from '@angular/forms'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { DialogComponent } from './dialog.component';
import { DomSanitizer } from '@angular/platform-browser';

export interface AlertConfig {
  title?: string;
  content?: string;
  close: string;
}

export interface ConfirmConfig {
  title?: string;
  content?: string;
  ok?: string;
  close: string;
}

@Injectable({
    providedIn: 'root'
  })
export class DialogService {

    constructor(
        private dialog: MatDialog, private dom: DomSanitizer
    ) {}

    alert(alert: AlertConfig) {
      const dialogRef = this.dialog.open(DialogComponent,
        { width: '287px', data: alert }
      );
      return dialogRef.afterClosed();
    }

    confirm(confirm: ConfirmConfig) {
      const dialogRef = this.dialog.open(DialogComponent,
        { width: '310px', data: { ...confirm, content: this.dom.bypassSecurityTrustHtml(confirm.content) }}
      );
      return dialogRef.afterClosed().pipe(map(Boolean));
    }
}
